 import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const QaWrapper = styled.div`

summary {
  position: relative;
  display: block; /* 矢印を消す */
  padding: 10px 10px 10px 30px; /* アイコンの余白を開ける */
  cursor: pointer; /* カーソルをポインターに */
  font-weight: bold;
  background-color: #e2f0f7;
  transition: 0.2s;
}
summary:hover {
  background-color: #ccebfb;
}
summary::-webkit-details-marker {
  display: none;　/* 矢印を消す */
}

/* 疑似要素でアイコンを表示 */
summary:before,
summary:after {
  content: "";
  margin: auto 0 auto 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
summary:before {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: #1da1ff;
}
summary:after {
  left: 6px;
  width: 5px;
  height: 5px;
  border: 4px solid transparent;
  border-left: 5px solid #fff;
  box-sizing: border-box;
  transition: .1s;
}

/* オープン時のスタイル */
details[open] summary {
  background-color: #ccebfb;
}
details div {
  margin: 0 0 0 2em;
}
details[open] summary:after {
  transform: rotate(90deg); /* アイコンを回転 */
  left: 4px; /* 位置を調整 */
  top: 5px; /* 位置を調整 */
}


/* アニメーション */
details[open] p {
  animation: fadeIn 0.5s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

`;

export const AboutWrapper = styled.div`
  position: relative;
  padding: 90px 75px 0 75px;
  @media (max-width: 990px) {
    padding: 80px 45px 30px 45px;
  }
  @media (max-width: 575px) {
    padding: 60px 25px 0 25px;
  }
`;

export const AboutPageTitle = styled.div`
  margin-bottom: 45px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet('colors.textColor', '#292929')};
    line-height: 1.53;
    margin-bottom: 10px;
    @media (max-width: 990px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export const AboutImage = styled.div`
  margin-bottom: 90px;
  @media (max-width: 990px) {
    margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

export const AboutDetails = styled.div`
  width: 870px;
  max-width: 100%;
  margin: 0 auto;

  h2 {
    font-size: 21px;
    font-weight: 500;
    color: ${themeGet('colors.textColor', '#292929')};
    margin-bottom: 40px;
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
`;
export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: '';
    width: 30px;
    height: 1px;
    background: #292929;
    display: block;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;
